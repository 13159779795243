.drawer-contents{
    border: 1px solid #a9bccc;
}

div .filterpanel{
    background-color:#0e6bae;
    color:white;
}

div .filterdds{
    border:none;
    font-family:"Gibson W01 SemiBold";
    text-transform: uppercase;
}

.filtercontents{
    font-family:"Gibson W01 SemiBold";
    text-transform: uppercase;
    text-align:left;
    padding:0;
    background-color: #86c2ec;
    border: 1px solid #c9ddf0;
}
.filtercontents span{
    /* color:#000; */
    font-family:"Gibson W01 Regular";
    font-size: 0.95em;
    /* white-space: auto; */
    list-style:none;
}

.filtercontents ul{
    margin: -1.5em 0;
}

.filtercontents ul li{
    /* margin: -1.5em 0; */
    /* white-space: nowrap; */
    list-style:none;
    line-height:2px;
}

.filter-radios{
    margin:0;
    color:black;
    font-family:"Gibson W01 Regular";
    text-transform: uppercase;
}

.MuiFormControlLabel-label{
    color:black;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.drawer-contents::-webkit-scrollbar {
    background-color:#fff;
    width:12px
}

/* background of the scrollbar except button or resizer */
.drawer-contents::-webkit-scrollbar-track {
    background-color:#fff
}

/* scrollbar itself */
.drawer-contents::-webkit-scrollbar-thumb {
    background-color:#c7dbe2;
    border-radius:5px;
    border:2px solid #fff
}

/* set button(top and bottom of the scrollbar) */
.drawer-contents::-webkit-scrollbar-button {display:none}

