/* Banner */

#mastercontainer{
    width:100%;
}

#userplaylist {
    background-attachment: fixed;
    /* background-color: #272833; */
    background-image: url("/images/dashboard_dark_3.jpg"); 
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    min-height: 100vh;
    position: relative;
    text-align: center;
    z-index: 21;
}

    /* #userplaylist:before {
        content: '';
        display: inline-block;
        height: 100vh;
        vertical-align: middle;
        width: 1%;
    }

    #userplaylist:after {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    } */

    #userplaylist .content {
        font-family:"Gibson W01 Regular";
        display: inline-block;
        font-weight:bold;
        margin-right: 1%;
        width: 95%;
        /* padding: 6em; */
        position: relative;
        /* text-align: right; */
        vertical-align: middle;
        z-index: 1;
    }

        #userplaylist .content header {
            display: inline-block;
            vertical-align: middle;
            color:white;
        }

            #userplaylist .content header h2 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1.6em;
                margin-top:20vh;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
            }



            #userplaylist .content h3 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1em;
                color:white;
            }
            	

            #userplaylist .content header h3 {
                font-family:"Gibson W01 SemiBold";
                color:white;
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                margin-top:-6%;
            }	
            
            #userplaylist .content h4 {
                font-size: 1.2em;
                color:white;
                margin-top:-3.5%;
                font-weight:900;
            }            

            #userplaylist .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

        #userplaylist .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #userplaylist .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #userplaylist {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #userplaylist .content {
        padding: 4.5em;
    }
}

@media screen and (max-width: 980px) {
		/* Banner */

        #userplaylist {
            background-attachment: scroll;
        }

            #userplaylist .goto-next {
                height: 7em;
            }

            #userplaylist .content {
                padding: 9em 0;
                text-align: center;
            }

                #userplaylist .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    text-align: center;
                }

                #userplaylist .content .image {
                    margin: 0;
                }

    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #userplaylist {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

            #userplaylist:before {
                height: calc(100vh - 44px);
            }

            #userplaylist .content {
                padding: 4.0625em 1.5em 4.875em 1.5em;
            }

                #userplaylist .content header h2 {
                    font-size: 1.5em;
                }

                #userplaylist .content .image {
                    height: 9em;
                    width: 9em;
                }
}

@media screen and (max-width: 480px) {
    #userplaylist .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}

.aboutcontent{
    background-color:rgba(255, 255, 255, 0.25)
}

.dashbar{
    background-color:#638aa1;
    height:45px;
    font-family:"Gibson W01 Regular";
}

.filtertab{
    background-color:#ffffff;
    height:45px;
    font-family:"Gibson W01 Regular";
}

.genretab{
    background-color:#c7dbe2;
    height:45px;
    font-family:"Gibson W01 Regular";
}

.searchtab{
    background-color:#4c7394;
    height:45px;
    font-family:"Gibson W01 Regular";
    color:white;
}

div .drawer-contents{
    background-color: #c7dbe2;
    font-family:"Gibson W01 SemiBold";
}


.panel-body h2{
    font-family:"Gibson W01 SemiBold";
    font-size: 1.6em;
    /* margin-top:20%; */
    /* text-transform: uppercase; */
    margin-left:1%;
    margin-top:3%;
    font-weight:bold;
    letter-spacing: 0pc; 
    text-align:left;    
    color:black;
    text-decoration: underline;           
}

.mdbblock{
    min-height: 700px;
    width: 65%;
    padding:25px;
}
@media screen and (max-width: 736px) {
    .mdbblock{
        width: 100%;
    }
}

.mdbcollapsed{
    width: 100%;
    padding:25px;
}

.ftexpanded{
    margin-left:43%;
}

.titlebadge{
    margin-left:10px;
}

.genrestabtxt{
    vertical-align: top;
    cursor:pointer;
    margin:0;
}

.fttoolbar{
    font-family:"Gibson W01 SemiBold";
    height:55px !important;
    background-color: white;
    float:right;
}

label span .audioswitch{
    font-family:"Gibson W01 SemiBold";
    font-size:1.75em;
}
/* 
.dropdown-menu{
    font-family:"Gibson W01 Regular";
    text-transform: uppercase;
    background-color:#c7dbe2;
    color:white;
} */

.trackhidden{
    display:none;
}