#userNavigation{
    background-color: #c4dbe3;
    border:none;
    /* min-width:300px;
     */
    /* position:fixed;
    top:25;
    right:0 */
}

#userTabs{
    background-color: #c4dbe3;
    border:none;
    /* padding: 24px; */
    /* position:fixed;
    top:25;
    right:0 */
}

.hidden{
    display:none;
}

.tabContainer{
    background-color: #c4dbe3;
    min-height:350px;
    min-width: 450px;
}

.userIcons{
    position: absolute;
    right:10px;
    height:45px;
    width:45px;
    text-align:center;
    background-color: white;
    /* font-size:55px; */
    padding-top:10px;
    padding-right:2px;
    border:none;
}

.MuiTab-textColorInherit .Mui-selected{
    border:none !important;
}

::-webkit-input-placeholder {
    font-style: italic;
 }
 :-moz-placeholder {
    font-style: italic;  
 }
 ::-moz-placeholder {
    font-style: italic;  
 }
 :-ms-input-placeholder {  
    font-style: italic; 
 }

 .card{
     border:none;
 }

 .passwordhint{
     font-size:0.85em;
     color:#363636;
 }