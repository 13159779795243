/* Banner */

#usercart {
    margin-top:5vh;
    background-color: #272833;
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    min-height: 100vh;
    position: relative;
    text-align: center;
    z-index: 21;
}

    #usercart:before {
        content: '';
        display: inline-block;
        height: 15vh;
        vertical-align: middle;
        width: 1%;
        margin-top:50px;
    }

    #usercart:after {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #usercart .content {
        font-family:"Gibson W01 Regular";
        display: inline-block;
        font-weight:bold;
        margin-right: 1%;
        /* margin-top: 5%; */
        max-width: 95%;
        padding: 6em;
        position: relative;
        text-align: right;
        vertical-align: top;
        z-index: 1;
    }

        #usercart .content header {
            display: inline-block;
            vertical-align: middle;
            color:black;
        }

            #usercart .content header h2 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1.6em;
                /* margin-top:3%; */
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                color:black;
            }

            #usercart .content h3 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1em;
                color:black;
            }	

            #usercart .content header h3 {
                font-family:"Gibson W01 SemiBold";
                color:black;
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                /* margin-top:-6%; */
            }	
            
            #usercart .content h4 {
                font-size: 1.2em;
                color:black;
                margin-top:-3.5%;
                font-weight:900;
            }            

            #usercart .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

        #usercart .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #usercart .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #usercart {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #usercart .content {
        padding: 4.5em;
    }
}

@media screen and (max-width: 980px) {
		/* Banner */

        #usercart {
            background-attachment: scroll;
        }

            #usercart .goto-next {
                height: 7em;
            }

            #usercart .content {
                padding: 9em 0;
                text-align: center;
            }

                #usercart .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    text-align: center;
                }

                #usercart .content .image {
                    margin: 0;
                }

    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #usercart {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

            #usercart:before {
                height: calc(100vh - 44px);
            }

            #usercart .content {
                padding: 4.0625em 1.5em 4.875em 1.5em;
            }

                #usercart .content header h2 {
                    font-size: 1.5em;
                }

                #usercart .content .image {
                    height: 9em;
                    width: 9em;
                }
}

@media screen and (max-width: 480px) {
    #usercart .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}

.aboutcontent{
    background-color:rgba(255, 255, 255, 0.25)
}

.form-control{
    border-style: solid;
    border-width: 1px;
    border-color: black;
}