#dashsearch{
    /* background-image: url('/images/icons/magnifying_glass.jpg');
    background-repeat: no-repeat;
    background-position: right; */
    text-transform: uppercase;
    font-style: italic;
    height:50px;
}

#smode{
    color:#74777a;
    font-family:"Gibson W01 SemiBold";
    vertical-align: middle;
    padding-top:3px;
    margin-left:15px;
    text-transform: uppercase;
    font-style: italic;  
}

span .MuiTypography-body1{
    font-size:0.85em;
    font-family:"Gibson W01 SemiBold";
}