/* Banner */

#searchtracks h2,h3 {
    color:#183546;
    font-family: "Gibson W01 SemiBold";
    /* text-align-last: center; */
}

#searchtracks .linkblock{
    padding:25px;
    margin-top:50px;
    background-color:rgba(255, 255, 255, 0.25);
    font-weight:900;
    font-family: "Gibson W01 Regular";
}

#searchtracks .linkblock2{
    padding:25px;
    /* background-color:rgba(255, 255, 255, 0.25); */
    font-weight:900;
    font-family: "Gibson W01 Regular";
}



#searchtracks ul{
    font-weight:700;
    list-style:none;
    text-align:left;
    line-height:2.5em;
    
}

#searchtracks ul a{
    border-bottom:none;
}


#searchtracks {
    background-attachment: fixed;
    /* background-color: #272833; */
    background-image: url("/images/Reg_User_home_board.jpg");
    background-position: top center;
    background-size: cover;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    /* max-height:155vh; */
    position: relative;
    text-align: center;
    z-index: 21;
}

    #searchtracks:before {
        content: '';
        display: inline-block;
        /* height: 100vh; */
        vertical-align: middle;
        width: 1%;
    }

    #searchtracks:after {
        /* background-image: -moz-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -webkit-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -ms-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");; */
        content: '';
        display: block;
        /* height: 100%; */
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #searchtracks .content {
        display: inline-block;
        margin-right: 1%;
        margin-top: 5%;
        max-width: 95%;
        padding: 6em;
        position: relative;
        /* text-align: right; */
        /* min-height: 125vh; */
        min-width: 55%;
        vertical-align: top;
        z-index: 1;
    }

        #searchtracks .content header {
            display: inline-block;
            vertical-align: middle;
        }

            #searchtracks .content h2 {
                font-family: "Gibson W01 SemiBold";
                font-size: 3em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
            }

            #searchtracks .content  h3 {
                font-family: "Gibson W01 SemiBold";
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                margin-top:-6%;
            }		
            
            #searchtracks .content h4 {
                font-family: "Gibson W01 SemiBold";
                font-size: 1.2em;
                color:#183546;
                margin-top:-5%;
                font-weight:900;
                text-transform: uppercase;
                padding-left:75px;
                padding-right:75px;
            }
            
            #searchtracks .content h5 {
                font-family: "Gibson W01 SemiBold";
                font-size: 0.90em;
                color:#183546;
                margin-top:-5%;
                font-weight:900;
                text-transform: uppercase;
                padding-left:75px;
                padding-right:75px;
            }	            

            #searchtracks .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

        #searchtracks .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #searchtracks .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #searchtracks {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #searchtracks .content {
        padding: 4.5em;
    }
    
}

@media screen and (max-width: 980px) {
		/* Banner */

        #searchtracks {
            background-attachment: scroll;
        }

            #searchtracks .goto-next {
                height: 7em;
            }

            #searchtracks .content {
                padding: 9em 0;
                text-align: center;
            }

                #searchtracks .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    text-align: center;
                }

                #searchtracks .content .image {
                    margin: 0;
                }
    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #searchtracks {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

        #searchtracks:before {
            height: calc(100vh - 44px);
        }

        #searchtracks .content {
            padding: 4.0625em 1.5em 4.875em 1.5em;
        }

            #searchtracks .content header h2 {
                font-size: 1.5em;
            }

            #searchtracks .content .image {
                height: 9em;
                width: 9em;
            }   
}

@media screen and (max-width: 480px) {
    #searchtracks .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}

.searchButtons{
    color:black;
    padding:10px;
    background:transparent;
    border:2px solid;
    border-color:black;
    /* border-radius: 3px; */
    text-align:center;
}

.searchButtons:hover {
    text-decoration: none;
}

#searchtracks tbody{
    /* text-transform: uppercase; */
    font-weight:900;
}

#searchtracks td{
    white-space: nowrap;
    font-weight:700;
    /* text-transform: uppercase; */
    border:none;
}