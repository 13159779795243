

#search h2,h3 {
    color:black;
    font-family:"Gibson W01 SemiBold";
    /* text-align-last: center; */
}

#search .linkblock{
    font-family:"Gibson W01 SemiBold";
    background-color:rgba(255, 255, 255, 0.25);
    text-align: center;
    font-size: 1.5em;
    padding:25px;
    width:65%;
}

@media screen and (max-width: 980px) {
    #search .linkblock{
        width:80%;
    }
}



#search ul{
    font-family:"Gibson W01 SemiBold";
    font-weight:700;
    list-style:none;
    text-align:left;
    line-height:2.5em;
}

#search ul a{
    border-bottom:none;
}


#search {
    background-attachment: fixed;
    /* background-color: #272833; */
    background-image: url("/images/Blue_board_homepage.jpg");
    background-position: top center;
    background-size: cover;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    /* height:250vh; */
    position: relative;
    text-align: center;
    z-index: 21;
}

    #search:before {
        content: '';
        display: inline-block;
        height: 100vh;
        vertical-align: middle;
        width: 1%;
    }

    #search:after {
        /* background-image: -moz-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -webkit-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -ms-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");; */
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #search .content {
        display: inline-block;
        margin-right: 1%;
        /* margin-top: 5%; */
        max-width: 95%;
        padding: 6em;
        position: relative;
        /* text-align: right; */
        vertical-align: top;
        z-index: 1;
    }

        #search .content header {
            display: inline-block;
            vertical-align: middle;
        }

            #search .content header h2 {
                font-family:"Gibson W01 SemiBold";
                font-size: 3em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
            }

            #search .content header h3 {
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                margin-top:-6%;
            }		
            
            #search .content h4 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1.2em;
                color:black;
                margin-top:-5%;
                font-weight:900;
                text-transform: uppercase;
                padding-left:75px;
                padding-right:75px;
            }	            

            #search .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

        #search .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #search .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #search {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #search .content {
        padding: 4.5em;
    }
    
}

@media screen and (max-width: 980px) {
		/* Banner */

        #search {
            background-attachment: scroll;
        }

            #search .goto-next {
                height: 7em;
            }

            #search .content {
                padding: 9em 0;
                text-align: center;
            }

                #search .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    text-align: center;
                }

                #search .content .image {
                    margin: 0;
                }
    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #search {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

        #search:before {
            height: calc(100vh - 44px);
        }

        #search .content {
            padding: 4.0625em 1.5em 4.875em 1.5em;
        }

            #search .content header h2 {
                font-size: 1.5em;
            }

            #search .content .image {
                height: 9em;
                width: 9em;
            }   
}

@media screen and (max-width: 480px) {
    #search .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}

.searchButtons{
    padding:10px;
    background:transparent;
    border:2px solid;
    border-color:black;
    /* border-radius: 3px; */
    text-align:center;
}