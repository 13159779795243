/* Banner */

#hitithardshowcase {
    background-attachment: fixed;
    /* background-color: #272833; */
    background-image: url("/images/Spotlights_About.jpg");
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    min-height: 135vh;
    position: relative;
    text-align: center;
    z-index: 21;
}

    #hitithardshowcase:before {
        content: '';
        display: inline-block;
        height: 100vh;
        vertical-align: middle;
        width: 1%;
    }

    #hitithardshowcase:after {
        /* background-image: -moz-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -webkit-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -ms-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");; */
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #hitithardshowcase .content {
        display: inline-block;
        font-weight:bold;
        margin-right: 1%;
        margin-top: 5%;
        max-width: 95%;
        /* padding: 6em; */
        position: relative;
        /* text-align: right; */
        vertical-align: top;
        z-index: 1;
    }

        #hitithardshowcase .content header {
            display: inline-block;
            vertical-align: middle;
            color:white;
        }

            #hitithardshowcase .content header h2 {
                font-size: 1.6em;
                /* margin-top:1%; */
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
            }

            #hitithardshowcase .content h3 {
                font-size: 1em;
                color:white;
            }	

            #hitithardshowcase .content header h3 {
                color:white;
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                margin-top:-6%;
            }				

            #hitithardshowcase .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

        #hitithardshowcase .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #hitithardshowcase .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #hitithardshowcase {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #hitithardshowcase .content {
        padding: 4.5em;
    }
}

@media screen and (max-width: 980px) {
		/* Banner */

        #hitithardshowcase {
            background-attachment: scroll;
        }

            #hitithardshowcase .goto-next {
                height: 7em;
            }

            #hitithardshowcase .content {
                padding: 9em 0;
                text-align: center;
            }

                #hitithardshowcase .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    text-align: center;
                }

                #hitithardshowcase .content .image {
                    margin: 0;
                }

    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #hitithardshowcase {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

            #hitithardshowcase:before {
                height: calc(100vh - 44px);
            }

            #hitithardshowcase .content {
                padding: 4.0625em 1.5em 4.875em 1.5em;
            }

                #hitithardshowcase .content header h2 {
                    font-size: 1.5em;
                }

                #hitithardshowcase .content .image {
                    height: 9em;
                    width: 9em;
                }
}

@media screen and (max-width: 480px) {
    #hitithardshowcase .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}

.aboutcontent{
    background-color:rgba(255, 255, 255, 0.25)
}
