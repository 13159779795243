#searchGroup{
    white-space: no-wrap;
}

#home_search{
    /* width:85%; */
    text-transform: uppercase;
    font-style: italic;
    height:35px;    
    
}
/* 
@media screen and (min-width: 736px) {
    #searchGroup {
        margin-left:0px,
    }    
} */