/* Banner */



#newreleases {
    background-attachment: fixed;
    background-image: url("/images/New_Releases_rt_art.jpg");
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    min-height: 150vh;
    position: relative;
    text-align: left;
    z-index: 21;
}
    /* #newreleases:before {
        content: '';
        display: inline-block;
        height: 100vh;
        vertical-align: middle;
        width: 1%;
    }

    #newreleases:after {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    } */

    #newreleases .content {
        font-family:"Gibson W01 Regular";
        display: inline-block;
        font-weight:bold;
        margin-right: 1%;
        width: 95%;
        /* padding: 6em; */
        position: relative;
        /* text-align: right; */
        vertical-align: middle;
        z-index: 1;
    }

        #newreleases .content header {
            display: inline-block;
            vertical-align: middle;
            color:white;
        }

            #newreleases .content header h2 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1.6em;
                margin-top:20vh;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
            }



            #newreleases .content h3 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1em;
                color:black;
                text-align: center
            }	

            #newreleases .content header h3 {
                font-family:"Gibson W01 SemiBold";
                color:white;
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                margin-top:-6%;
            }	
            
            #newreleases .content h4 {
                font-size: 1.2em;
                color:white;
                margin-top:-3.5%;
                font-weight:900;
            }            

            #newreleases .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

        #newreleases .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #newreleases .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #newreleases {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #newreleases .content {
        padding: 4.5em;
    }
}

@media screen and (max-width: 980px) {
		/* Banner */

        #newreleases {
            background-attachment: scroll;
        }

            #newreleases .goto-next {
                height: 7em;
            }

            #newreleases .content {
                padding: 9em 0;
                text-align: center;
            }

                #newreleases .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    text-align: center;
                }

                #newreleases .content .image {
                    margin: 0;
                }

    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #newreleases {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

            #newreleases:before {
                height: calc(100vh - 44px);
            }

            #newreleases .content {
                padding: 4.0625em 1.5em 4.875em 1.5em;
            }

                #newreleases .content header h2 {
                    font-size: 1.5em;
                }

                #newreleases .content .image {
                    height: 9em;
                    width: 9em;
                }
}

@media screen and (max-width: 480px) {
    #newreleases .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}

.aboutcontent{
    background-color:rgba(255, 255, 255, 0.25)
}

.dashbar{
    background-color:#638aa1;
    height:45px;
    font-family:"Gibson W01 Regular";
}

.filtertab{
    background-color:#ffffff;
    height:45px;
    font-family:"Gibson W01 Regular";
}

.genretab{
    background-color:#c7dbe2;
    height:45px;
    font-family:"Gibson W01 Regular";
}

.searchtab{
    background-color:#4c7394;
    height:45px;
    font-family:"Gibson W01 Regular";
    color:white;
}

.searchtab a{
    font-family:"Gibson W01 Regular";
    color:white;
}

.searchtab a:hover{
    text-decoration: none;
    color:white;
}

div .drawer-contents{
    background-color: #c7dbe2;
    font-family:"Gibson W01 SemiBold";
}


.panel-body h2{
    font-family:"Gibson W01 SemiBold";
    font-size: 1.6em;
    /* margin-top:20%; */
    /* text-transform: uppercase; */
    margin-left:1%;
    margin-top:3%;
    font-weight:bold;
    letter-spacing: 0pc; 
    text-align:left;    
    color:black;
    text-decoration: underline;           
}

.mdbblock{
    min-height: 700px;
    width: 65%;
    padding:25px;
}
@media screen and (max-width: 736px) {
    .mdbblock{
        width: 100%;
    }
}

.mdbcollapsed{
    width: 100%;
    padding:25px;
}

.ftexpanded{
    margin-left:43%;
}

.titlebadge{
    margin-left:10px;
}

.genrestabtxt{
    vertical-align: top;
    cursor:pointer;
    margin:0;
}

.fttoolbar{
    font-family:"Gibson W01 SemiBold";
    height:55px !important;
    background-color: white;
    float:right;
}

label span .audioswitch{
    font-family:"Gibson W01 SemiBold";
    font-size:1.75em;
}

.categorytitle{
    text-align:left;
    font-family:"Gibson W01 Regular";
    font-size:0.95em;
}



#catdropdown {
    margin-top: 4px;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 100% !important;
    background-color: #4c7394;
    width:75%;
    border-width:1px;
    border-color:black;
}

#catdropdown a{
    color:whitesmoke;
}

#catdropdown a:hover{
    background-color:#4c7394;
    color:white !important;
}

#catdropdown a:active{
    background-color:#c7dbe2;
}

div #mastercontainer{
    background-color: "white";
    min-height: 900; 
}
