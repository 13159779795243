
#userorders h2,h3 {
    font-family:"Gibson W01 SemiBold";
    color:black;
    /* text-align-last: center; */
}

#userorders .linkblock{
    font-family:"Gibson W01 Regular";
    min-height:50%;
    line-height:1.8em;
    text-align:left;
    font-size:1.1em;
    padding:25px;
    /* padding:25px; */
    /* padding-left:27%;
    padding-top:25px;
    padding-bottom:25px; */
    background-color:rgba(0, 0, 0, 0.25);
}



#userorders ul{
    font-weight:700;
    list-style:none;
    text-align:left;
    line-height:2.5em;
    
}

#userorders ul a{
    border-bottom:none;
}


#userorders {
    background-attachment: fixed;
    color:white;
    background-color: #272833;
    /* background-image: url("/images/HIH_Guitar.jpg");
    background-position: top center;
    background-size: cover; */
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    /* height:155vh; */
    position: relative;
    text-align: center;
    z-index: 21;
}

    #userorders:before {
        content: '';
        display: inline-block;
        height: 100vh;
        vertical-align: middle;
        width: 1%;
    }

    #userorders:after {
        /* background-image: -moz-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -webkit-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -ms-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");; */
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #userorders .content {
        display: inline-block;
        margin-right: 1%;
        margin-top: 5%;
        width: 80vw;
        /* padding: 6em; */
        position: relative;
        /* text-align: right; */
        vertical-align: top;
        z-index: 1;
    }

        #userorders .content header {
            display: inline-block;
            vertical-align: middle;
        }

            #userorders .content header h2 {
                color:white;
                font-size: 3em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                margin-top:50px;
            }

            #userorders .content header h3 {
                color:white;
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                margin-top:-6%;
            }		
            
            #userorders .content h4 {
                font-size: 1.2em;
                color:white;
                margin-top:-5%;
                font-weight:900;
                text-transform: uppercase;
                padding-left:75px;
                padding-right:75px;
            }	            

            #userorders .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

        #userorders .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #userorders .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #userorders {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #userorders .content {
        padding: 4.5em;
    }
    
}

@media screen and (max-width: 980px) {
		/* Banner */

        #userorders {
            background-attachment: scroll;
        }

            #userorders .goto-next {
                height: 7em;
            }

            #userorders .content {
                padding: 9em 0;
                text-align: center;
            }

                #userorders .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    text-align: center;
                }

                #userorders .content .image {
                    margin: 0;
                }
    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #userorders {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

        #userorders:before {
            height: calc(100vh - 44px);
        }

        #userorders .content {
            padding: 4.0625em 1.5em 4.875em 1.5em;
        }

            #userorders .content header h2 {
                font-size: 1.5em;
            }

            #userorders .content .image {
                height: 9em;
                width: 9em;
            }   
}

@media screen and (max-width: 480px) {
    #userorders .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}

table{
    font-family:"Gibson W01 SemiBold";

}


table>thead>tr{
    color:white;
    font-family:"Gibson W01 SemiBold";

}

/* .profilelink a {
    font-size:1.1em
}

.d-none>.d-sm-block{
    margin:0
} */