.svg_icons{
    transform: scale(1.4);
  }


.ftTable{
    color:#74777a;
    padding:25px;
    font-weight: normal;
}

.trackIdTitle{
    font-family:"Gibson W01 Regular";
    font-size:0.55em;
    font-weight: normal;
}

.trackDescript{
    text-align:left;
    font-family:"Gibson W01 Regular";
    font-size:0.78em;
    color:#74777a;
}

.artistNameSpan{
    font-family:"Gibson W01 Regular";
    font-size:0.75em;
    color:#74777a;  
}

.ftPlayBtn{
    margin-top:25px;
}

.mixBut{
    margin-left:10px;
    margin-right:10px;
}



.trackTitle{
    font-family:"Gibson W01 Regular";
    font-size:0.95em;
    white-space: wrap;
    color:black;
}


table td{
    display: table-cell;
    padding: 14px 40px 14px 16px;
    font-size: 0.79em !important;
    text-align: left;
    font-family:"Gibson W01 Regular";
    font-weight:bold;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}

.playIcons{
    font-size: 2.5rem;
}

.trackactionblock{
    white-space: nowrap;
}

.trackActions{
    font-size:0.75em;
    margin-left:15px;
    margin-right:15px;
    white-space: nowrap;
}