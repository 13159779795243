@font-face{
	font-family:"Gibson W01 SemiBold";
	src:url("./Fonts/5597017/324d1b76-3b45-4dda-b19f-4301d31306ce.eot?#iefix");
	src:url("./Fonts/5597017/324d1b76-3b45-4dda-b19f-4301d31306ce.eot?#iefix") format("eot"),url("Fonts/5597017/b568c9a3-433b-4da5-8f93-fc35864fbd0a.woff2") format("woff2"),url("Fonts/5597017/f6b0a767-4754-40d2-a8fc-71540d60a9d4.woff") format("woff"),url("Fonts/5597017/e9432694-66ff-4f47-ae48-08498830fd1b.ttf") format("truetype");
}
@font-face{
	font-family:"Gibson W01 Regular";
	src:url("Fonts/5597029/8d055bc4-04be-49c5-8e2d-5f011f08a7d0.eot?#iefix");
	src:url("Fonts/5597029/8d055bc4-04be-49c5-8e2d-5f011f08a7d0.eot?#iefix") format("eot"),url("Fonts/5597029/b838e0b5-6105-488c-a276-8868336ed0da.woff2") format("woff2"),url("Fonts/5597029/ccb47f69-e164-40d9-adec-4ece125856f3.woff") format("woff"),url("Fonts/5597029/04951d97-084d-46ad-9a42-ba9f9813ef41.ttf") format("truetype");
}


html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}



/* Basic */

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	html, body {
		background: #ffffff;
	}

	body.is-preload *, body.is-preload *:before, body.is-preload *:after {
		-moz-animation: none !important;
		-webkit-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
		-moz-transition: none !important;
		-webkit-transition: none !important;
		-ms-transition: none !important;
		transition: none !important;
	}

	body, input, select, textarea {
		/* color: rgba(255, 255, 255, 0.75); */
		font-family: "Gibson W01 Regular";
		font-size: 15pt;
		font-weight: 100;
		line-height: 1.75em;
	}

	a {
		-moz-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
		/* border-bottom: dotted 1px; */
		color:#143548;
		text-decoration: none;
	}

		a:hover {
			color:#3389bb !important;
			/* border-bottom-color: transparent; */
		}

	strong, b {
		color: #ffffff;
		font-weight: 300;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: #ffffff;
		font-weight: 300;
		line-height: 1em;
		margin: 0 0 1em 0;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			border: 0;
		}

	h2 {
		font-size: 2em;
		line-height: 1.5em;
		letter-spacing: -0.025em;
	}

	h3 {
		font-size: 1.35em;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.1em;
		line-height: 1.5em;
	}

	h5 {
		font-size: 0.9em;
		line-height: 1.5em;
	}

	h6 {
		font-size: 0.7em;
		line-height: 1.5em;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	hr {
		border: 0;
		border-bottom: solid 1px rgba(255, 255, 255, 0.3);
		margin: 3em 0;
	}

		hr.major {
			margin: 4em 0;
		}

	blockquote {
		border-left: solid 4px rgba(255, 255, 255, 0.3);
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	code {
		background: rgba(255, 255, 255, 0.075);
		border-radius: 4px;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0 2em 0;
	}

		pre code {
			display: block;
			line-height: 1.75em;
			padding: 1em 1.5em;
			overflow-x: auto;
		}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}




/* Container */

	.container {
		margin: 0 auto;
		max-width: calc(100% - 4em);
		width: 70em;
	}

		.container.xsmall {
			width: 17.5em;
		}

		.container.small {
			width: 35em;
		}

		.container.medium {
			width: 52.5em;
		}

		.container.large {
			width: 87.5em;
		}

		.container.xlarge {
			width: 105em;
		}

		.container.max {
			width: 100%;
		}

		@media screen and (max-width: 1280px) {

			.container {
				width: 90%;
				max-width: 100%;
			}

		}

		@media screen and (max-width: 980px) {

			.container {
				width: 100% !important;
			}

		}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.3333333333%;
		}

		.row > .off-1 {
			margin-left: 8.3333333333%;
		}

		.row > .col-2 {
			width: 16.6666666667%;
		}

		.row > .off-2 {
			margin-left: 16.6666666667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.3333333333%;
		}

		.row > .off-4 {
			margin-left: 33.3333333333%;
		}

		.row > .col-5 {
			width: 41.6666666667%;
		}

		.row > .off-5 {
			margin-left: 41.6666666667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.3333333333%;
		}

		.row > .off-7 {
			margin-left: 58.3333333333%;
		}

		.row > .col-8 {
			width: 66.6666666667%;
		}

		.row > .off-8 {
			margin-left: 66.6666666667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.3333333333%;
		}

		.row > .off-10 {
			margin-left: 83.3333333333%;
		}

		.row > .col-11 {
			width: 91.6666666667%;
		}

		.row > .off-11 {
			margin-left: 91.6666666667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0;
			margin-left: 0em;
		}

			.row.gtr-0 > * {
				padding: 0 0 0 0em;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0em;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0em;
				}

		.row.gtr-25 {
			margin-top: 0;
			margin-left: -0.625em;
		}

			.row.gtr-25 > * {
				padding: 0 0 0 0.625em;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -0.625em;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 0.625em;
				}

		.row.gtr-50 {
			margin-top: 0;
			margin-left: -1.25em;
		}

			.row.gtr-50 > * {
				padding: 0 0 0 1.25em;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -1.25em;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 1.25em;
				}

		.row {
			margin-top: 0;
			margin-left: -2.5em;
		}

			.row > * {
				padding: 0 0 0 2.5em;
			}

			.row.gtr-uniform {
				margin-top: -2.5em;
			}

				.row.gtr-uniform > * {
					padding-top: 2.5em;
				}

		.row.gtr-150 {
			margin-top: 0;
			margin-left: -3.75em;
		}

			.row.gtr-150 > * {
				padding: 0 0 0 3.75em;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -3.75em;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 3.75em;
				}

		.row.gtr-200 {
			margin-top: 0;
			margin-left: -5em;
		}

			.row.gtr-200 > * {
				padding: 0 0 0 5em;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -5em;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 5em;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xlarge {
					order: -1;
				}

				.row > .col-1-xlarge {
					width: 8.3333333333%;
				}

				.row > .off-1-xlarge {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-xlarge {
					width: 16.6666666667%;
				}

				.row > .off-2-xlarge {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-xlarge {
					width: 25%;
				}

				.row > .off-3-xlarge {
					margin-left: 25%;
				}

				.row > .col-4-xlarge {
					width: 33.3333333333%;
				}

				.row > .off-4-xlarge {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-xlarge {
					width: 41.6666666667%;
				}

				.row > .off-5-xlarge {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-xlarge {
					width: 50%;
				}

				.row > .off-6-xlarge {
					margin-left: 50%;
				}

				.row > .col-7-xlarge {
					width: 58.3333333333%;
				}

				.row > .off-7-xlarge {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-xlarge {
					width: 66.6666666667%;
				}

				.row > .off-8-xlarge {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-xlarge {
					width: 75%;
				}

				.row > .off-9-xlarge {
					margin-left: 75%;
				}

				.row > .col-10-xlarge {
					width: 83.3333333333%;
				}

				.row > .off-10-xlarge {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-xlarge {
					width: 91.6666666667%;
				}

				.row > .off-11-xlarge {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-xlarge {
					width: 100%;
				}

				.row > .off-12-xlarge {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.625em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.625em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.625em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.625em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1.25em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1.25em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1.25em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1.25em;
						}

				.row {
					margin-top: 0;
					margin-left: -2.5em;
				}

					.row > * {
						padding: 0 0 0 2.5em;
					}

					.row.gtr-uniform {
						margin-top: -2.5em;
					}

						.row.gtr-uniform > * {
							padding-top: 2.5em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3.75em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3.75em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3.75em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3.75em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -5em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 5em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -5em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 5em;
						}

		}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-large {
					order: -1;
				}

				.row > .col-1-large {
					width: 8.3333333333%;
				}

				.row > .off-1-large {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-large {
					width: 16.6666666667%;
				}

				.row > .off-2-large {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-large {
					width: 25%;
				}

				.row > .off-3-large {
					margin-left: 25%;
				}

				.row > .col-4-large {
					width: 33.3333333333%;
				}

				.row > .off-4-large {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-large {
					width: 41.6666666667%;
				}

				.row > .off-5-large {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-large {
					width: 50%;
				}

				.row > .off-6-large {
					margin-left: 50%;
				}

				.row > .col-7-large {
					width: 58.3333333333%;
				}

				.row > .off-7-large {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-large {
					width: 66.6666666667%;
				}

				.row > .off-8-large {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-large {
					width: 75%;
				}

				.row > .off-9-large {
					margin-left: 75%;
				}

				.row > .col-10-large {
					width: 83.3333333333%;
				}

				.row > .off-10-large {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-large {
					width: 91.6666666667%;
				}

				.row > .off-11-large {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-large {
					width: 100%;
				}

				.row > .off-12-large {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.625em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.625em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.625em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.625em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1.25em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1.25em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1.25em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1.25em;
						}

				.row {
					margin-top: 0;
					margin-left: -2.5em;
				}

					.row > * {
						padding: 0 0 0 2.5em;
					}

					.row.gtr-uniform {
						margin-top: -2.5em;
					}

						.row.gtr-uniform > * {
							padding-top: 2.5em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3.75em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3.75em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3.75em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3.75em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -5em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 5em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -5em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 5em;
						}

		}

		@media screen and (max-width: 980px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-medium {
					order: -1;
				}

				.row > .col-1-medium {
					width: 8.3333333333%;
				}

				.row > .off-1-medium {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-medium {
					width: 16.6666666667%;
				}

				.row > .off-2-medium {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-medium {
					width: 25%;
				}

				.row > .off-3-medium {
					margin-left: 25%;
				}

				.row > .col-4-medium {
					width: 33.3333333333%;
				}

				.row > .off-4-medium {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-medium {
					width: 41.6666666667%;
				}

				.row > .off-5-medium {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-medium {
					width: 50%;
				}

				.row > .off-6-medium {
					margin-left: 50%;
				}

				.row > .col-7-medium {
					width: 58.3333333333%;
				}

				.row > .off-7-medium {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-medium {
					width: 66.6666666667%;
				}

				.row > .off-8-medium {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-medium {
					width: 75%;
				}

				.row > .off-9-medium {
					margin-left: 75%;
				}

				.row > .col-10-medium {
					width: 83.3333333333%;
				}

				.row > .off-10-medium {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-medium {
					width: 91.6666666667%;
				}

				.row > .off-11-medium {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-medium {
					width: 100%;
				}

				.row > .off-12-medium {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.625em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.625em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.625em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.625em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1.25em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1.25em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1.25em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1.25em;
						}

				.row {
					margin-top: 0;
					margin-left: -2.5em;
				}

					.row > * {
						padding: 0 0 0 2.5em;
					}

					.row.gtr-uniform {
						margin-top: -2.5em;
					}

						.row.gtr-uniform > * {
							padding-top: 2.5em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3.75em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3.75em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3.75em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3.75em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -5em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 5em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -5em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 5em;
						}

		}

		@media screen and (max-width: 736px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-small {
					order: -1;
				}

				.row > .col-1-small {
					width: 8.3333333333%;
				}

				.row > .off-1-small {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-small {
					width: 16.6666666667%;
				}

				.row > .off-2-small {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-small {
					width: 25%;
				}

				.row > .off-3-small {
					margin-left: 25%;
				}

				.row > .col-4-small {
					width: 33.3333333333%;
				}

				.row > .off-4-small {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-small {
					width: 41.6666666667%;
				}

				.row > .off-5-small {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-small {
					width: 50%;
				}

				.row > .off-6-small {
					margin-left: 50%;
				}

				.row > .col-7-small {
					width: 58.3333333333%;
				}

				.row > .off-7-small {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-small {
					width: 66.6666666667%;
				}

				.row > .off-8-small {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-small {
					width: 75%;
				}

				.row > .off-9-small {
					margin-left: 75%;
				}

				.row > .col-10-small {
					width: 83.3333333333%;
				}

				.row > .off-10-small {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-small {
					width: 91.6666666667%;
				}

				.row > .off-11-small {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-small {
					width: 100%;
				}

				.row > .off-12-small {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.625em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.625em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.625em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.625em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1.25em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1.25em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1.25em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1.25em;
						}

				.row {
					margin-top: 0;
					margin-left: -2.5em;
				}

					.row > * {
						padding: 0 0 0 2.5em;
					}

					.row.gtr-uniform {
						margin-top: -2.5em;
					}

						.row.gtr-uniform > * {
							padding-top: 2.5em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3.75em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3.75em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3.75em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3.75em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -5em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 5em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -5em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 5em;
						}

		}

		@media screen and (max-width: 480px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xsmall {
					order: -1;
				}

				.row > .col-1-xsmall {
					width: 8.3333333333%;
				}

				.row > .off-1-xsmall {
					margin-left: 8.3333333333%;
				}

				.row > .col-2-xsmall {
					width: 16.6666666667%;
				}

				.row > .off-2-xsmall {
					margin-left: 16.6666666667%;
				}

				.row > .col-3-xsmall {
					width: 25%;
				}

				.row > .off-3-xsmall {
					margin-left: 25%;
				}

				.row > .col-4-xsmall {
					width: 33.3333333333%;
				}

				.row > .off-4-xsmall {
					margin-left: 33.3333333333%;
				}

				.row > .col-5-xsmall {
					width: 41.6666666667%;
				}

				.row > .off-5-xsmall {
					margin-left: 41.6666666667%;
				}

				.row > .col-6-xsmall {
					width: 50%;
				}

				.row > .off-6-xsmall {
					margin-left: 50%;
				}

				.row > .col-7-xsmall {
					width: 58.3333333333%;
				}

				.row > .off-7-xsmall {
					margin-left: 58.3333333333%;
				}

				.row > .col-8-xsmall {
					width: 66.6666666667%;
				}

				.row > .off-8-xsmall {
					margin-left: 66.6666666667%;
				}

				.row > .col-9-xsmall {
					width: 75%;
				}

				.row > .off-9-xsmall {
					margin-left: 75%;
				}

				.row > .col-10-xsmall {
					width: 83.3333333333%;
				}

				.row > .off-10-xsmall {
					margin-left: 83.3333333333%;
				}

				.row > .col-11-xsmall {
					width: 91.6666666667%;
				}

				.row > .off-11-xsmall {
					margin-left: 91.6666666667%;
				}

				.row > .col-12-xsmall {
					width: 100%;
				}

				.row > .off-12-xsmall {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.625em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.625em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.625em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.625em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -1.25em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 1.25em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -1.25em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 1.25em;
						}

				.row {
					margin-top: 0;
					margin-left: -2.5em;
				}

					.row > * {
						padding: 0 0 0 2.5em;
					}

					.row.gtr-uniform {
						margin-top: -2.5em;
					}

						.row.gtr-uniform > * {
							padding-top: 2.5em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -3.75em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 3.75em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -3.75em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 3.75em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -5em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 5em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -5em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 5em;
						}

		}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}
	

	header p {
		color: #ffffff;
		position: relative;
		margin: 0 0 1.5em 0;
	}

	header h2 + p {
		font-size: 1.25em;
		margin-top: -1em;
		line-height: 1.75em;
	}

	header h3 + p {
		font-size: 1.1em;
		margin-top: -0.8em;
		line-height: 1.75em;
	}

	header h4 + p,
	header h5 + p,
	header h6 + p {
		font-size: 0.9em;
		margin-top: -0.6em;
		line-height: 1.5em;
	}

	header.major {
		margin: 0 0 4em 0;
		position: relative;
		text-align: center;
	}

		header.major:after {
			background: #e44c65;
			content: '';
			display: inline-block;
			height: 0.2em;
			max-width: 20em;
			width: 75%;
		}

	footer.major {
		margin: 4em 0 0 0;
	}

/* Form */

	form {
		margin: 0 0 2em 0;
	}

		form.cta {
			max-width: 35em;
			margin-left: auto;
			margin-right: auto;
		}

	label {
		color: #ffffff;
		display: block;
		font-size: 0.9em;
		font-weight: 300;
		margin: 0 0 1em 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select,
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: border-color 0.2s ease-in-out;
		-webkit-transition: border-color 0.2s ease-in-out;
		-ms-transition: border-color 0.2s ease-in-out;
		transition: border-color 0.2s ease-in-out;
		/* background: transparent; */
		border-radius: 4px;
		border: solid 1px rgba(255, 255, 255, 0.3);
		/* color: inherit; */
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

		input[type="text"]:invalid,
		input[type="password"]:invalid,
		input[type="email"]:invalid,
		select:invalid,
		textarea:invalid {
			box-shadow: none;
		}

		input[type="text"]:focus,
		input[type="password"]:focus,
		input[type="email"]:focus,
		select:focus,
		textarea:focus {
			border-color: #e44c65;
		}

	select {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.3)' /%3E%3C/svg%3E");
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1rem) center;
		height: 3em;
		padding-right: 3em;
		text-overflow: ellipsis;
	}

		select option {
			color: #ffffff;
			/* background: #1c1d26; */
		}

		select:focus::-ms-value {
			background-color: transparent;
		}

		select::-ms-expand {
			display: none;
		}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: 3em;
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;
	}

		input[type="checkbox"] + label,
		input[type="radio"] + label {
			text-decoration: none;
			color: rgba(255, 255, 255, 0.75);
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: 100;
			padding-left: 2.55em;
			padding-right: 0.75em;
			position: relative;
		}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-transform: none !important;
			}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				border-radius: 4px;
				border: solid 1px rgba(255, 255, 255, 0.3);
				content: '';
				display: inline-block;
				height: 1.8em;
				left: 0;
				line-height: 1.725em;
				position: absolute;
				text-align: center;
				top: 0;
				width: 1.8em;
			}

		input[type="checkbox"]:checked + label:before,
		input[type="radio"]:checked + label:before {
			background: rgba(255, 255, 255, 0.25);
			color: #ffffff;
			content: '\f00c';
		}

		input[type="checkbox"]:focus + label:before,
		input[type="radio"]:focus + label:before {
			border-color: #e44c65;
		}

	input[type="checkbox"] + label:before {
		border-radius: 4px;
	}

	input[type="radio"] + label:before {
		border-radius: 100%;
	}

	::-webkit-input-placeholder {
		color: rgba(0, 0, 0, 0.5) !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: rgba(0, 0, 0, 0.5) !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: rgba(0, 0, 0, 0.5) !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: rgba(0, 0, 0, 0.5) !important;
		opacity: 1.0;
	}

/* Box */

	.box {
		border-radius: 4px;
		border: solid 1px rgba(255, 255, 255, 0.3);
		margin-bottom: 2em;
		padding: 1.5em;
	}

		.box > :last-child,
		.box > :last-child > :last-child,
		.box > :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		.box.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}

/* Icon */

	.icon {
		text-decoration: none;
		border-bottom: none;
		position: relative;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-transform: none !important;
		}

		.icon > .label {
			display: none;
		}

		.icon.alt {
			text-decoration: none;
		}

			.icon.alt:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-transform: none !important;
			}

			.icon.alt:before {
				color: #1c1d26 !important;
				text-shadow: 1px 0 0 #ffffff, -1px 0 0 #ffffff, 0 1px 0 #ffffff, 0 -1px 0 #ffffff;
			}

		.icon.major {
			background: #272833;
			border-radius: 100%;
			cursor: default;
			display: inline-block;
			height: 6em;
			line-height: 6em;
			margin: 0 0 2em 0;
			text-align: center;
			width: 6em;
		}

			.icon.major:before {
				font-size: 2.25em;
			}

			.icon.major.alt {
				text-decoration: none;
			}

				.icon.major.alt:before {
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					text-transform: none !important;
				}

				.icon.major.alt:before {
					color: #272833 !important;
					text-shadow: 1px 0 0 #ffffff, -1px 0 0 #ffffff, 0 1px 0 #ffffff, 0 -1px 0 #ffffff;
				}

/* Image */

	.image {
		border-radius: 4px;
		border: 0;
		display: inline-block;
		position: relative;
		overflow: hidden;
	}

		.image:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			background-image: url("/images/overlay.png");
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.image img {
			border-radius: 4px;
			display: block;
		}

		.image.left {
			float: left;
			margin: 0 1.5em 1em 0;
			top: 0.25em;
		}

		.image.right {
			float: right;
			margin: 0 0 1em 1.5em;
			top: 0.25em;
		}

		.image.left, .image.right {
			max-width: 40%;
		}

			.image.left img, .image.right img {
				width: 100%;
			}

		.image.fit {
			display: block;
			margin: 0 0 2em 0;
			width: 100%;
		}

			.image.fit img {
				width: 100%;
			}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 2em 0;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

	ul {
		list-style: disc;
		margin: 0 0 2em 0;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

		ul.alt {
			list-style: none;
			padding-left: 0;
		}

			ul.alt li {
				border-top: solid 1px rgba(255, 255, 255, 0.3);
				padding: 0.5em 0;
			}

				ul.alt li:first-child {
					border-top: 0;
					padding-top: 0;
				}

	dl {
		margin: 0 0 2em 0;
	}

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;
	}

		ul.icons li {
			display: inline-block;
			height: 2.5em;
			line-height: 2.5em;
			padding: 0 0.5em;
		}

			ul.icons li .icon {
				font-size: 0.8em;
			}

				ul.icons li .icon:before {
					font-size: 2em;
				}

/* Actions */

	ul.actions {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		cursor: default;
		list-style: none;
		margin-left: -1em;
		padding-left: 0;
	}

		ul.actions li {
			padding: 0 0 0 1em;
			vertical-align: middle;
		}

		ul.actions.special {
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			width: 100%;
			margin-left: 0;
		}

			ul.actions.special li:first-child {
				padding-left: 0;
			}

		ul.actions.stacked {
			-moz-flex-direction: column;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-left: 0;
		}

			ul.actions.stacked li {
				padding: 1.3em 0 0 0;
			}

				ul.actions.stacked li:first-child {
					padding-top: 0;
				}

		ul.actions.fit {
			width: calc(100% + 1em);
		}

			ul.actions.fit li {
				-moz-flex-grow: 1;
				-webkit-flex-grow: 1;
				-ms-flex-grow: 1;
				flex-grow: 1;
				-moz-flex-shrink: 1;
				-webkit-flex-shrink: 1;
				-ms-flex-shrink: 1;
				flex-shrink: 1;
				width: 100%;
			}

				ul.actions.fit li > * {
					width: 100%;
				}

			ul.actions.fit.stacked {
				width: 100%;
			}

		@media screen and (max-width: 480px) {

			ul.actions:not(.fixed) {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
				margin-left: 0;
				width: 100% !important;
			}

				ul.actions:not(.fixed) li {
					-moz-flex-grow: 1;
					-webkit-flex-grow: 1;
					-ms-flex-grow: 1;
					flex-grow: 1;
					-moz-flex-shrink: 1;
					-webkit-flex-shrink: 1;
					-ms-flex-shrink: 1;
					flex-shrink: 1;
					padding: 1em 0 0 0;
					text-align: center;
					width: 100%;
				}

					ul.actions:not(.fixed) li > * {
						width: 100%;
					}

					ul.actions:not(.fixed) li:first-child {
						padding-top: 0;
					}

					ul.actions:not(.fixed) li input[type="submit"],
					ul.actions:not(.fixed) li input[type="reset"],
					ul.actions:not(.fixed) li input[type="button"],
					ul.actions:not(.fixed) li button,
					ul.actions:not(.fixed) li .button {
						width: 100%;
					}

						ul.actions:not(.fixed) li input[type="submit"].icon:before,
						ul.actions:not(.fixed) li input[type="reset"].icon:before,
						ul.actions:not(.fixed) li input[type="button"].icon:before,
						ul.actions:not(.fixed) li button.icon:before,
						ul.actions:not(.fixed) li .button.icon:before {
							margin-left: -0.5em;
						}

		}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 2em 0;
		width: 100%;
	}

		table tbody tr {
			border: solid 1px rgba(255, 255, 255, 0.3);
			border-left: 0;
			border-right: 0;
		}

			table tbody tr:nth-child(2n + 1) {
				background-color: rgba(255, 255, 255, 0.075);
			}

		table td {
			padding: 0.75em 0.75em;
		}

		table th {
			color: #ffffff;
			font-size: 0.9em;
			font-weight: 300;
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		table thead {
			border-bottom: solid 1px rgba(255, 255, 255, 0.3);
		}

		table tfoot {
			border-top: solid 1px rgba(255, 255, 255, 0.3);
		}

		table.alt {
			border-collapse: separate;
		}

			table.alt tbody tr td {
				border: solid 1px rgba(255, 255, 255, 0.3);
				border-left-width: 0;
				border-top-width: 0;
			}

				table.alt tbody tr td:first-child {
					border-left-width: 1px;
				}

			table.alt tbody tr:first-child td {
				border-top-width: 1px;
			}

			table.alt thead {
				border-bottom: 0;
			}

			table.alt tfoot {
				border-top: 0;
			}

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		/* background-color: transparent; */
		/* border-radius: 4px; */
		border: 0;
		box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
		/* color: #ffffff !important; */
		cursor: pointer;
		display: inline-block;
		font-weight: 300;
		height: 3em;
		line-height: 3em;
		padding: 0 2.25em;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
	}

		input[type="submit"]:hover, input[type="submit"]:active,
		input[type="reset"]:hover,
		input[type="reset"]:active,
		/* input[type="button"]:hover,
		input[type="button"]:active,
		.button:hover,
		.button:active {
			box-shadow: inset 0 0 0 1px #1b26c4;
			color: #304BA3 !important;
		} */

		input[type="submit"]:active,
		input[type="reset"]:active,
		input[type="button"]:active,
		.button:active {
			background-color: rgba(228, 76, 101, 0.15);
		}

		input[type="submit"].icon:before,
		input[type="reset"].icon:before,
		input[type="button"].icon:before,
		.button.icon:before {
			margin-right: 0.5em;
		}

		input[type="submit"].fit,
		input[type="reset"].fit,
		input[type="button"].fit,
		.button.fit {
			width: 100%;
		}

		input[type="submit"].small,
		input[type="reset"].small,
		input[type="button"].small,
		.button.small {
			font-size: 0.8em;
		}

		input[type="submit"].large,
		input[type="reset"].large,
		input[type="button"].large,
		.button.large {
			font-size: 1.35em;
		}

		input[type="submit"].primary,
		input[type="reset"].primary,
		input[type="button"].primary,
		.button.primary {
			background-color: #e44c65;
			box-shadow: none;
			color: #ffffff !important;
		}

			input[type="submit"].primary:hover,
			input[type="reset"].primary:hover,
			input[type="button"].primary:hover,
			.button.primary:hover {
				background-color: #e76278;
			}

			input[type="submit"].primary:active,
			input[type="reset"].primary:active,
			input[type="button"].primary:active,
			.button.primary:active {
				background-color: #e13652;
			}

		input[type="submit"].disabled, input[type="submit"]:disabled,
		input[type="reset"].disabled,
		input[type="reset"]:disabled,
		input[type="button"].disabled,
		input[type="button"]:disabled,
		.button.disabled,
		.button:disabled {
			background-color: rgba(255, 255, 255, 0.3) !important;
			box-shadow: none !important;
			color: #ffffff !important;
			cursor: default;
			opacity: 0.25;
		}

/* Goto Next */

	.goto-next {
		border: 0;
		bottom: 0;
		display: block;
		height: 5em;
		left: 50%;
		margin: 0 0 0 -5em;
		overflow: hidden;
		position: absolute;
		text-indent: 10em;
		white-space: nowrap;
		width: 10em;
		z-index: 1;
	}

		.goto-next:before {
			background-image: url("/assets/css/images/arrow.svg");
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			content: '';
			display: block;
			height: 1.5em;
			left: 50%;
			margin: -0.75em 0 0 -1em;
			position: absolute;
			top: 50%;
			width: 2em;
			z-index: 1;
		}

		.goto-next-black {
			border: 0;
			bottom: 0;
			display: block;
			height: 5em;
			left: 50%;
			margin: 0 0 0 -5em;
			overflow: hidden;
			position: absolute;
			text-indent: 10em;
			white-space: nowrap;
			width: 10em;
			z-index: 1;
		}
	
			.goto-next-black:before {
				background-image: url("/assets/css/images/arrow-black.svg");
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				content: '';
				display: block;
				height: 1.5em;
				left: 50%;
				margin: -0.75em 0 0 -1em;
				position: absolute;
				top: 50%;
				width: 2em;
				z-index: 1;
			}	


/* Spotlight */

	.spotlight {
		background-attachment: fixed;
		background-position: center center;
		background-size: cover;
		box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
		height: 100vh;
		overflow: hidden;
		position: relative;
	}

		.spotlight:nth-last-of-type(1) {
			z-index: 1;
		}

		.spotlight:nth-last-of-type(2) {
			z-index: 2;
		}

		.spotlight:nth-last-of-type(3) {
			z-index: 3;
		}

		.spotlight:nth-last-of-type(4) {
			z-index: 4;
		}

		.spotlight:nth-last-of-type(5) {
			z-index: 5;
		}

		.spotlight:nth-last-of-type(6) {
			z-index: 6;
		}

		.spotlight:nth-last-of-type(7) {
			z-index: 7;
		}

		.spotlight:nth-last-of-type(8) {
			z-index: 8;
		}

		.spotlight:nth-last-of-type(9) {
			z-index: 9;
		}

		.spotlight:nth-last-of-type(10) {
			z-index: 10;
		}

		.spotlight:nth-last-of-type(11) {
			z-index: 11;
		}

		.spotlight:nth-last-of-type(12) {
			z-index: 12;
		}

		.spotlight:nth-last-of-type(13) {
			z-index: 13;
		}

		.spotlight:nth-last-of-type(14) {
			z-index: 14;
		}

		.spotlight:nth-last-of-type(15) {
			z-index: 15;
		}

		.spotlight:nth-last-of-type(16) {
			z-index: 16;
		}

		.spotlight:nth-last-of-type(17) {
			z-index: 17;
		}

		.spotlight:nth-last-of-type(18) {
			z-index: 18;
		}

		.spotlight:nth-last-of-type(19) {
			z-index: 19;
		}

		.spotlight:nth-last-of-type(20) {
			z-index: 20;
		}

		.spotlight:before {
			background-image: url("/images/overlay.png");
			content: '';
			display: block;
			height: 100%;
			left: 0;
			top: 0;
			width: 100%;
		}

		.spotlight .image.main {
			display: none;
		}

			.spotlight .image.main img {
				position: relative;
			}

		.spotlight .content {
			-moz-transform: translate(0,0);
			-webkit-transform: translate(0,0);
			-ms-transform: translate(0,0);
			transform: translate(0,0);
			-moz-transition: -moz-transform 1s ease, opacity 1s ease;
			-webkit-transition: -webkit-transform 1s ease, opacity 1s ease;
			-ms-transition: -ms-transform 1s ease, opacity 1s ease;
			transition: transform 1s ease, opacity 1s ease;
			background: rgba(23, 24, 32, 0.95);
			border-style: solid;
			opacity: 1;
			position: absolute;
		}

		.spotlight .goto-next {
			-moz-transform: translate(0,0);
			-webkit-transform: translate(0,0);
			-ms-transform: translate(0,0);
			transform: translate(0,0);
			-moz-transition: -moz-transform 0.75s ease, opacity 1s ease-in;
			-webkit-transition: -webkit-transform 0.75s ease, opacity 1s ease-in;
			-ms-transition: -ms-transform 0.75s ease, opacity 1s ease-in;
			transition: transform 0.75s ease, opacity 1s ease-in;
			-moz-transition-delay: 0.5s;
			-webkit-transition-delay: 0.5s;
			-ms-transition-delay: 0.5s;
			transition-delay: 0.5s;
			opacity: 1;
		}

		.spotlight.top .content, .spotlight.bottom .content {
			left: 0;
			padding: 5.1em 0 3.1em 0;
			width: 100%;
		}

		.spotlight.top .content {
			border-bottom-width: 0.35em;
			top: 0;
		}

		.spotlight.bottom .content {
			border-top-width: 0.35em;
			bottom: 0;
		}

		.spotlight.left .content, .spotlight.right .content {
			height: 101%;
			padding: 6em 3em;
			top: 0;
			width: 28em;
		}

		.spotlight.left .content {
			border-right-width: 0.35em;
			left: 0;
		}

		.spotlight.right .content {
			border-left-width: 0.35em;
			right: 0;
		}

		.spotlight.style1 .content {
			border-color: #e44c65;
		}

		.spotlight.style2 .content {
			border-color: #5480f1;
		}

		.spotlight.style3 .content {
			width:100%;
			background:transparent;
			color:black;
			text-align:center;
			text-transform: uppercase;
			/* border-color: #39c088; */
		}

		.spotlight.style3 h2{
			color:black;
			/* margin-top:-50px; */
		} 

		.spotlight.style3 p{
			color:black;
		} 		

		/* .spotlight.style3 .searchBlock{
			background-color:rgba(255, 255, 255, 0.65);
			width:45%;
			padding:75px;
			line-height:2em;
			text-align:left;
			margin-left:5%;
			margin-top:75px;
		}  */
		
		.spotlight.style3 .searchBlock li{
			list-style: none;
		}

		.spotlight.style3 .searchBlock a {
			color:black;
			text-decoration: none;
			border-bottom: none;
		}		
		

		.spotlight.inactive .content {
			opacity: 0;
		}

		.spotlight.inactive .goto-next {
			-moz-transform: translate(0,1.5em);
			-webkit-transform: translate(0,1.5em);
			-ms-transform: translate(0,1.5em);
			transform: translate(0,1.5em);
			opacity: 0;
		}

		.spotlight.inactive.top .content {
			-moz-transform: translate(0,-5em);
			-webkit-transform: translate(0,-5em);
			-ms-transform: translate(0,-5em);
			transform: translate(0,-5em);
		}

		.spotlight.inactive.bottom .content {
			-moz-transform: translate(0,5em);
			-webkit-transform: translate(0,5em);
			-ms-transform: translate(0,5em);
			transform: translate(0,5em);
		}

		.spotlight.inactive.left .content {
			-moz-transform: translate(-5em,0);
			-webkit-transform: translate(-5em,0);
			-ms-transform: translate(-5em,0);
			transform: translate(-5em,0);
		}

		.spotlight.inactive.right .content {
			-moz-transform: translate(5em,0);
			-webkit-transform: translate(5em,0);
			-ms-transform: translate(5em,0);
			transform: translate(5em,0);
		}

	body.is-touch .spotlight {
		background-attachment: scroll;
	}

/* Wrapper */

	.wrapper {
		padding: 6em 0 4em 0;
	}

		.wrapper.style2 {
			background: #e44c65;
		}

			.wrapper.style2 input[type="text"]:focus,
			.wrapper.style2 input[type="password"]:focus,
			.wrapper.style2 input[type="email"]:focus,
			.wrapper.style2 select:focus,
			.wrapper.style2 textarea:focus {
				border-color: rgba(255, 255, 255, 0.5);
			}

			.wrapper.style2 input[type="submit"]:hover, .wrapper.style2 input[type="submit"]:active,
			.wrapper.style2 input[type="reset"]:hover,
			.wrapper.style2 input[type="reset"]:active,
			.wrapper.style2 input[type="button"]:hover,
			.wrapper.style2 input[type="button"]:active,
			.wrapper.style2 .button:hover,
			.wrapper.style2 .button:active {
				background-color: rgba(255, 255, 255, 0.075) !important;
				box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
				color: #ffffff !important;
			}

			.wrapper.style2 input[type="submit"]:active,
			.wrapper.style2 input[type="reset"]:active,
			.wrapper.style2 input[type="button"]:active,
			.wrapper.style2 .button:active {
				background-color: rgba(255, 255, 255, 0.25) !important;
			}

			.wrapper.style2 input[type="submit"].primary,
			.wrapper.style2 input[type="reset"].primary,
			.wrapper.style2 input[type="button"].primary,
			.wrapper.style2 .button.primary {
				background-color: #ffffff;
				color: #e44c65 !important;
			}

				.wrapper.style2 input[type="submit"].primary:hover, .wrapper.style2 input[type="submit"].primary:active,
				.wrapper.style2 input[type="reset"].primary:hover,
				.wrapper.style2 input[type="reset"].primary:active,
				.wrapper.style2 input[type="button"].primary:hover,
				.wrapper.style2 input[type="button"].primary:active,
				.wrapper.style2 .button.primary:hover,
				.wrapper.style2 .button.primary:active {
					background-color: rgba(255, 255, 255, 0.075) !important;
					box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
					color: #ffffff !important;
				}

				.wrapper.style2 input[type="submit"].primary:active,
				.wrapper.style2 input[type="reset"].primary:active,
				.wrapper.style2 input[type="button"].primary:active,
				.wrapper.style2 .button.primary:active {
					background-color: rgba(255, 255, 255, 0.25) !important;
				}

		.wrapper.fade-down > .container {
			-moz-transform: translate(0,0);
			-webkit-transform: translate(0,0);
			-ms-transform: translate(0,0);
			transform: translate(0,0);
			-moz-transition: -moz-transform 1s ease, opacity 1s ease;
			-webkit-transition: -webkit-transform 1s ease, opacity 1s ease;
			-ms-transition: -ms-transform 1s ease, opacity 1s ease;
			transition: transform 1s ease, opacity 1s ease;
			opacity: 1;
		}

		.wrapper.fade-down.inactive > .container {
			-moz-transform: translate(0,-1em);
			-webkit-transform: translate(0,-1em);
			-ms-transform: translate(0,-1em);
			transform: translate(0,-1em);
			opacity: 0;
		}

		.wrapper.fade-up > .container {
			-moz-transform: translate(0,0);
			-webkit-transform: translate(0,0);
			-ms-transform: translate(0,0);
			transform: translate(0,0);
			-moz-transition: -moz-transform 1s ease, opacity 1s ease;
			-webkit-transition: -webkit-transform 1s ease, opacity 1s ease;
			-ms-transition: -ms-transform 1s ease, opacity 1s ease;
			transition: transform 1s ease, opacity 1s ease;
			opacity: 1;
		}

		.wrapper.fade-up.inactive > .container {
			-moz-transform: translate(0,1em);
			-webkit-transform: translate(0,1em);
			-ms-transform: translate(0,1em);
			transform: translate(0,1em);
			opacity: 0;
		}

		.wrapper.fade > .container {
			-moz-transition: opacity 1s ease;
			-webkit-transition: opacity 1s ease;
			-ms-transition: opacity 1s ease;
			transition: opacity 1s ease;
			opacity: 1;
		}

		.wrapper.fade.inactive > .container {
			opacity: 0;
		}

/* Dropotron */

	.dropotron {
		background: rgba(255, 255, 8255, 0.75);
		border-radius: 4px;
		box-shadow: 0 0.075em 0.35em 0 rgba(56, 55, 55, 0.5);
		list-style: none;
		margin-top: calc(-0.25em + 1px);
		min-width: 25em;
		padding: 0.25em 0;
	}

		.dropotron > li {
			border-top: solid 1px rgba(255, 255, 255, 0.035);
			padding: 0;
		}

			.dropotron > li a, .dropotron > li span {
				border: 0;
				color: rgba(255, 255, 255, 0.75);
				display: block;
				padding: 0.1em 1em;
				text-decoration: none;
			}

			.dropotron > li:first-child {
				border-top: 0;
			}

			.dropotron > li.active > a, .dropotron > li.active > span {
				color: #e44c65;
			}

		.dropotron.level-0 {
			font-size: 0.8em;
			margin-top: 1em;
		}

			.dropotron.level-0:before {
				-moz-transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				background: #ffffff;
				content: '';
				display: block;
				height: 1em;
				position: absolute;
				right: 1.5em;
				top: -0.5em;
				width: 1em;
			}

	body.landing .dropotron.level-0 {
		margin-top: 0;
	}

/* Header */


	#logo-container {
		float: left;
		margin: 0 3.06%;
		margin-top: 0px;
		margin-left: 10px;
	}
	#logo-container a {
		display: block;
		float: left;
		border-bottom:none;
	}
	#logo-container img {
		height: 50px;
	
	}

	#page-wrapper {
		padding-top: 3.5em;
	}

	body.landing #page-wrapper {
		padding-top: 0;
	}

	body.landing #header {
		background: rgba(255, 255, 255, 1);
		box-shadow: none;
		position: fixed;
	}

	body.landing #header a{
		color:black;
	}	

/* XLarge */

	@media screen and (max-width: 1680px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 13pt;
			}

	}

/* Large */

	@media screen and (max-width: 1280px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 11.5pt;
			}

		/* Spotlight */

			.spotlight.top .content {
				padding: 3.825em 0 1.825em 0;
			}

			.spotlight.bottom .content {
				padding: 3.825em 0 2.95em 0;
			}

			.spotlight.left .content, .spotlight.right .content {
				padding: 4.5em 2.5em;
				width: 25em;
			}

		/* Wrapper */

			.wrapper {
				padding: 4.5em 0 2.5em 0;
			}

		/* Dropotron */

			.dropotron.level-0 {
				font-size: 1em;
			}


		/* Footer */

			#footer {
				padding: 4.5em 0;
				font-family:"Gibson W01 Regular"
			}

	}

/* Medium */

	@media screen and (max-width: 980px) {

		/* Basic */

			body, input, select, textarea {
				font-size: 12pt;
			}

		/* Spotlight */

			.spotlight {
				background-attachment: scroll;
				height: auto;
			}

				.spotlight .image.main {
					display: block;
					margin: 0;
					max-height: 40vh;
					overflow: hidden;
				}

				.spotlight .content {
					/* background-color: #1c1d26; */
					border-width: 0 !important;
					border-top-width: 0.35em !important;
					bottom: auto !important;
					left: auto !important;
					padding: 4.5em 2.5em 2.5em 2.5em !important;
					position: relative;
					right: auto !important;
					text-align: center;
					top: auto !important;
					width: 100% !important;
				}

					.spotlight .content ul.actions {
						-moz-justify-content: center;
						-webkit-justify-content: center;
						-ms-justify-content: center;
						justify-content: center;
						width: 100%;
						margin-left: 0;
					}

						.spotlight .content ul.actions li:first-child {
							padding-left: 0;
						}

				.spotlight .goto-next {
					display: none;
				}

		/* Wrapper */

			.wrapper {
				padding: 4.5em 2.5em 2.5em 2.5em;
			}

		/* Footer */

			#footer {
				padding: 4.5em 0;
				font-family:"Gibson W01 Regular"
			}

	}

/* Small */

	#navPanel, #titleBar {
		display: none;
	}

	@media screen and (max-width: 736px) {

		/* Basic */

			html, body {
				overflow-x: hidden;
			}

			body, input, select, textarea {
				font-size: 12pt;
			}

			h2 {
				font-size: 1.5em;
			}

			h3 {
				font-size: 1.2em;
			}

			h4 {
				font-size: 1em;
			}

		/* Section/Article */

			header p br {
				display: none;
			}

			header h2 + p {
				font-size: 1em;
			}

			header h3 + p {
				font-size: 1em;
			}

			header h4 + p,
			header h5 + p,
			header h6 + p {
				font-size: 0.9em;
			}

			header.major {
				margin: 0 0 2em 0;
			}

		/* Goto Next */

			.goto-next:before {
				height: 0.8em;
				margin: -0.4em 0 0 -0.6em;
				width: 1.2em;
			}

		/* Spotlight */

			.spotlight {
				box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
			}

				.spotlight .image.main {
					max-height: 60vh;
				}

				.spotlight .content {
					/* border-top-width: 0.2em !important; */
					padding: 3.25em 1.5em 1.25em 1.5em !important;
				}

		/* Wrapper */

			.wrapper {
				padding: 3.25em 1.5em 1.25em 1.5em;
			}

		/* Header */

			#header {
				display: none;
			}


		/* Footer */

			#footer {
				padding: 3.25em 1.5em;
				font-family:"Gibson W01 Regular";
			}

	}

/* XSmall */

	@media screen and (max-width: 480px) {

		/* Basic */

			html, body {
				min-width: 320px;
			}

			body, input, select, textarea {
				font-size: 12pt;
			}

		/* Button */

			input[type="submit"],
			input[type="reset"],
			input[type="button"],
			.button {
				padding: 0;
			}

		/* Spotlight */

			.spotlight .image.main {
				max-height: 50vh;
			}

			.spotlight .content {
				padding: 3em 1.25em 1em 1.25em !important;
			}

		/* Wrapper */

			.wrapper {
				padding: 3em 1.25em 1em 1.25em;
			}

		/* Footer */

			#footer {
				padding: 3em 1.25em;
				font-family:"Gibson W01 Regular";
			}

				#footer .copyright {
					line-height: inherit;
				}

					#footer .copyright li {
						border-left: 0;
						display: block;
						margin: 0;
						padding: 0;
					}

	}

	.card-container.card {
		font-family: "Gibson W01 Regular";
		max-width: 350px;
		padding: 20px 20px;
	}

	.card-container.card h2 {
		font-family: "Gibson W01 SemiBold";
		color:rgba(0, 0, 0, 0.75);
	}

	.card-container.card h3 {
		font-family: "Gibson W01 SemiBold";
		color:rgba(0, 0, 0, 0.75);
		font-size:1.3em;
		margin-top:-25px;
		letter-spacing: -1px;
	}	
	
	/* .btn {
		font-weight: 700;
		height: 36px;
		-moz-user-select: none;
		-webkit-user-select: none;
		user-select: none;
		cursor: default;
	} */
	
	/*
	 * Card component
	 */
	.card {
		background-color: #C4DBE3;
		/* just in case there no content*/
		padding: 20px 25px 30px;
		margin: 0 auto 75px;
		margin-top: 50px;
		/* shadows and rounded borders */
		/*-moz-border-radius: 2px;
		-webkit-border-radius: 2px;
		border-radius: 2px;
		 -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
	}
	
	.profile-img-card {
		width: 96px;
		height: 96px;
		margin: 0 auto 10px;
		display: block;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		border-radius: 50%;
	}
	
	/*
	 * Form styles
	 */
	.profile-name-card {
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		margin: 10px 0 0;
		min-height: 1em;
	}
	
	.reauth-email {
		display: block;
		color: #404040;
		line-height: 2;
		margin-bottom: 10px;
		font-size: 14px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.form-signin{
		color:#333333;
	}
	
	.form-signin #inputEmail,
	.form-signin #inputPassword {
		/* background-color: #ffffff; */
		direction: ltr;
		height: 44px;
		font-size: 16px;
	}
	
	.form-signin input[type=email],
	.form-signin input[type=password],
	.form-signin input[type=text],
	.form-signin button {
		width: 100%;
		display: block;
		margin-bottom: 10px;
		z-index: 1;
		position: relative;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	
	.form-signin .form-control:focus {
		border-color: rgb(104, 145, 162);
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
	}
	
	.btn.btn-signin {
		font-family: "Gibson W01 Regular";
		/*background-color: #4d90fe; */
		background-color: rgb(104, 145, 162);
		/* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
		padding: 0px;
		font-weight: 700;
		font-size: 17px;
		height: 36px;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		border: none;
		-o-transition: all 0.218s;
		-moz-transition: all 0.218s;
		-webkit-transition: all 0.218s;
		transition: all 0.218s;
	}
	
	/* .btn.btn-signin:hover,
	.btn.btn-signin:active,
	.btn.btn-signin:focus {
		background-color: rgb(12, 97, 33);
	} */
	
	.forgot-password {
		color: rgb(104, 145, 162);
	}
	
	.forgot-password:hover,
	.forgot-password:active,
	.forgot-password:focus{
		color: rgb(12, 97, 33);
	}



#nav-user-ico{
	margin-top:30px;
}

.ftheadlinks{
	margin-right:150px;
	/* word-spacing: 25px; */
}

.ftheadlinks a{
	margin-right:25px;
	font-family: "Gibson W01 Regular";
	font-size: 1.0em;
	font-weight:500;
	text-transform: uppercase;
	color:black;
}

/* .bg-light {
	background-color:rgba(255, 255, 255, 0.75)  !important;
} */

.navbar a{
	font-family:"Gibson W01 Regular";
	border-bottom:none;
}

.userBoxes{
	cursor:pointer;
}

#unavigation.unExpanded{
	right:100;
}

div.mbExpanded{
	width:40%;
	transition: all .1s ease-in-out;
	/* border-radius: 5px; */
	min-height:60%;
	position: fixed;
	top:10;
	right:0;
}

div.mbCollapsed{
	width:100px;
	transition: all .2s ease-in-out;
	border-radius: 15px;
}

.cancel-ico{
	color:rgb(78, 97, 150)  !important;
	margin-top:10px;
	margin-right:35px;
}

.cancel-ico a{
	border-bottom:none;
}

.top-nav-collapse{
	opacity: 0.5;
	transition: all .2s ease-in-out;
}

div.is-danger{
	font-size:0.75em;
	color:red;
}



.navbar a{
	white-space: nowrap;
	font-weight:bold;
	color:black;
}

.navbar-light .navbar-nav .nav-link{
	white-space: nowrap;
	font-weight:bold;
	color:black;
}

/* @media (max-width:767px) {
	.navbar-header {
	  display:flex;
	}
  }
   */
  @media (max-width:767px) {
	.navbar-brand {
		margin-right:100px;
	  
	}
  }
  
  /* @media (max-width:767px) {
	.navbar-toggle {
	  order:0;
	  margin-left:15px;
	  margin-right:0;
	}
  } */


.dropdown-menu{
    font-family:"Gibson W01 Regular";
    text-transform: uppercase;
    background-color:whitesmoke;
	color:black;
	border-radius: 0px;
}

.dropdown-menu a{
    color:black;
}

.artistlink{
	color :#ec407a;
  }
