/* Banner */

#custom {
    font-family:"Gibson W01 Regular";
    background-attachment: fixed;
    /* background-color: #272833; */
    background-image: url("/images/Amp_mikes_homepage.jpg");
    background-position: center center;
    background-size: cover;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25);
    /* max-height:120vh; */
    position: relative;
    z-index: 21;
}

    #custom:before {
        content: '';
        display: inline-block;
        /* height: 100vh; */
        vertical-align: middle;
        width: 1%;
    }

    #custom:after {
        /* background-image: -moz-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -webkit-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: -ms-linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");;
        background-image: linear-gradient(top, rgba(23, 24, 32, 0.95), rgba(23, 24, 32, 0.95)), url("/images/overlay.png");; */
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #custom .content {
        font-family:"Gibson W01 Regular";
        color:white;
        font-weight:700;
        display: inline-block;
        margin-right: 1%;
        /* margin-top: 5%; */
        max-width: 95%;
        padding: 6em;
        position: relative;
        vertical-align: top;
        z-index: 1;
        width: 100%;
        /* text-align:justify; */
    }

        #custom .content header {
            vertical-align: middle;
            text-align:center;
        }

            #custom .content header h2 {
                font-family:"Gibson W01 SemiBold";
                font-size: 3em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
            }


            #custom .content header h3 {
                font-family:"Gibson W01 SemiBold";
                font-size: 1.5em;
                margin: .5;
                text-transform: uppercase;
                font-weight:bold;
                letter-spacing: 0pc;
                text-align:center;
                margin-top:-6%;
            }				

            #custom .content header p {
                margin: 0.5em 0 0 0;
                top: 0;
            }

            .custButRow{
                margin-top:"5%";
                text-align:center;
            }

            .custBut{
                color:white;
                font-weight:bold;
                border:1px solid;
                margin-top:10%;
                border-radius:none;;
            }

        #custom .content .image {
            border-radius: 100%;
            display: inline-block;
            height: 18em;
            margin-left: 3em;
            vertical-align: middle;
            width: 18em;
        }

            #custom .content .image img {
                border-radius: 100%;
                display: block;
                width: 100%;
            }

body.is-touch #custom {
    background-attachment: scroll;
}

@media screen and (max-width: 1280px) {
    #custom .content {
        padding: 4.5em;
    }
}

@media screen and (max-width: 980px) {
		/* Banner */

        #custom {
            background-attachment: scroll;
        }

            #custom .goto-next {
                height: 7em;
            }

            #custom .content {
                padding: 9em 0;
                
            }

                #custom .content header {
                    display: block;
                    margin: 0 0 2em 0;
                    
                }

                #custom .content .image {
                    margin: 0;
                }

    /* Banner */    
}

@media screen and (max-width: 736px) {
		/* Banner */

        #custom {
            box-shadow: 0 0.125em 0.5em 0 rgba(0, 0, 0, 0.25);
            min-height: calc(100vh - 44px);
        }

            #custom:before {
                height: calc(100vh - 44px);
            }

            #custom .content {
                padding: 4.0625em 1.5em 4.875em 1.5em;
            }

                #custom .content header h2 {
                    font-size: 1.5em;
                }

                #custom .content .image {
                    height: 9em;
                    width: 9em;
                }
}

@media screen and (max-width: 480px) {
    #custom .content {
        padding: 3em 1.5625em 5.25em 1.5625em;
    }    
}