

.audiowrapper{
    color:whitesmoke;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height:100px;
    background-color: #0e6bae;
    text-align: center;
    border: 1px solid #c1c1c1;
    z-index:9000;
    padding-top:9px;

  }

  .audiowrapper a{
    color:whitesmoke;
  }

  .audiowrapper a:hover{
    color:whitesmoke;
  }

.waveform{
    background-color:#9eb1d4;
    position: relative;
    padding:0 !important;
    margin:0 !important;
    border-radius: 4px;
    border: solid 1px rgba(255, 255, 255, 0.3);  
    height:55px;

}  

.wave{
    /* margin-top:2vh; */
    vertical-align: middle;
    width: 100%;
}

.ftwaveform{
    margin:0 !important;
    padding:0 !important;;
}

.ft-audio-wrapper{
    position:relative;
    border: 1px solid #000;
}

.fttimeline{
    margin:0 !important;
    padding:0 !important;
}

.audiobtn-wrapper{
    margin-top:3vh;
}
.audiobtn{
    color:#000000;
    width:50px;
    /* margin-left:15px; */
    font-size:1.0em;
}

.styles_reactWaves__1M36F{
    width:100% !important;
    padding: 0;
    margin:0 !important;
    height:100%;   
  }

  .react-waves{
      width:100%;
  }

  .audio-info{
    font-size:0.70em;
    color:whitesmoke;
    max-height:20px;
    margin-bottom:3px;
  }

  .audio-info .startcounter{
      text-align:left;
  }

  .audio-info .endcounter{
      text-align:right;
      margin-right:15px;
}

.audio-info .trackinfo{
    text-transform: uppercase;
}

.player-disabled{
    display:none;
}

.player-enabled{
    display:inline;
}